<template>
  <v-form
        ref="form"
        v-model="valid"
        lazy-validation
  > 
    <v-row>
      <v-col
            cols="12"
            sm="4"
      >
          <v-combobox
            v-model="type"
            :items="itemsTipo"
            label="Selecciona un Tipo"
          ></v-combobox>            
      </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="title"
              label="Titulo"                     
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="host"
              label="Host"     
              ref="name"
              :rules="[
                required('host')
              ]"         
            />
          </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="port"
          ref="port"
          label="Puerto"          
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="protocol"
          label="Protocolo"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="channel"
          ref="channel"
          label="Canal"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
        <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
          v-model="email"
          label="Email"
        />
        </v-col>
        <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
          v-model="username"
          label="Usuario"
        />
        </v-col>
    </v-row>

    <v-row>
        <v-col
        cols="12"
        sm="4"
        >
        <v-text-field
          v-model="password"
          label="password"
        />
        </v-col>
        <v-col
        cols="12"
        sm="4"
        >
          <v-text-field
            v-model="encryption"
            label="encryption"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="code"
            label="code"
          />          
        </v-col>
    </v-row>

    <v-row>        
        <v-col
          cols="12"
          sm="4"
        >          
          <v-text-field
            v-model="key"
            label="key"
          />            
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >      
          <v-text-field
            v-model="key_id"
            label="key id"
          />                
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="key_secret"
            label="key secret"
          />          
        </v-col>
      </v-row>
      <v-row>                
        <v-col
          cols="12"
          sm="4"
        >      
          <v-text-field
            v-model="token1"
            label="token1"
          />                
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="token2"
            label="token2"
          />          
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >          
          <v-text-field
            v-model="refresh_token"
            label="refresh_token"
          />   
        </v-col>
      </v-row>            

      <v-btn color="success" v-on:click="submit" :disabled="!valid">Guardar <v-icon>save</v-icon></v-btn>
  </v-form>
</template>


<script>
import validations from '@/utils/validation';
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';

export default {
  name: "pluginsadd",
  computed:{
    ...mapState(['currentUser'])    
  },   
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){
      
    },
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        // let branch_id=this.currentUser.branch_id;
        const {title,host,port,protocol,encryption,channel,email,username,password,code,key,key_id,key_secret,token1,token2,refresh_token, type} = this;    
        await baseApi().post(`/plugins`,JSON.stringify({title,host,port,protocol,encryption,channel,email,username,password,code,key,key_id,key_secret,token1,token2,refresh_token, type })).then((response)=>{
              if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });    

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }      
    }
  },
  data: function() {
    return {
      ...validations,
      title:'',
      host:'',
      port:'0',
      protocol:'',
      encryption:'',
      channel:'',
      email:'',
      username:'',
      password:'',
      code:'',
      key:'',
      key_id:'',
      key_secret:'',
      token1:'',
      token2:'',
      refresh_token:'',
      type:'Gmail',             
      itemsTipo: [
          'CTConnect',
          'CVA',
          'Gmail',
          'Mailchimp',
          'ML',
          'Telegram',
          'Syscom',
          'SW',
          'Woocomerce',
      ]
    };                  
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nuevo Plugin",
        title:"Nuevo Plugin",
        subtitle:"primary",
        description:"Este formulario se agregará un nuevo registro de plugin"
    });
    this.getBreadcrumps()    
  }
}

</script>